import React from 'react';
import { styled } from 'linaria/react';
import FooterLinks from './FooterLinks';
import SocialLinks from './SocialLinks';
import { theme } from '../../Theme';
import { ReactComponent as DHL } from '../../../svg/DhlLogo.svg';
import { ReactComponent as Klarna } from '../../../svg/Klarna.svg';
import NewsletterField from '../../Newsletter/NewsletterField';
import NewsletterQuery from './NewsletterQuery.gql';
import { useQuery } from '@apollo/react-hooks';
import useChannelCheck from '../../../utils/useChannelCheck';

const PoweredByWrapper = styled('div')``;

const Wrapper = styled('section')`
  background: #333;
  color: white;

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }
`;

const LowerFooter = styled('div')``;
const Inner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 2rem;
  max-width: 80rem;
  margin: auto;

  &.bottom {
    align-items: stretch;
    flex-direction: row-reverse;
    ${theme.below.lg} {
      display: block;
    }
  }
`;
const Column = styled('div')`
  max-width: 50%;
  margin: 0;

  p {
    font-size: 1rem;
    letter-spacing: 0.02em;
    font-weight: 300;
    line-height: 1.5;
  }

  &.left {
    ${theme.below.lg} {
      width: 100%;
      max-width: 100%;
    }
    ul li {
      a {
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.02em;
        color: #333;
        ${theme.below.lg} {
          font-size: 22px;
          line-height: 36px;
        }
      }
    }
  }

  &.right {
    ${theme.below.lg} {
      max-width: 150px;
      width: auto;
    }
    h2 {
      font-size: 2.5rem;
      line-height: 1.2;
      letter-spacing: 0.06em;
      font-family: ${theme.fonts.secondary};
      ${theme.below.lg} {
        font-size: 1.5rem;
        line-height: 1;
      }
    }
    text-align: right;
  }

  &.payment {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 250px;
    ${theme.below.lg} {
      max-width: 100%;
      text-align: left;
      align-items: flex-start;
    }
    img {
      margin-left: 15px;
    }
    svg {
      flex: 1;
      margin-left: 15px;
      ${theme.below.lg} {
        margin: 0 15px 0 0;
      }
    }
    .dhl-svg {
      polygon {
        fill: white;
      }
      path {
        fill: black;
      }
    }
  }

  div.group {
    margin-bottom: 20px;
    > * {
      display: block;
    }
  }

  div.address {
    > * > * {
      display: block;
    }
  }

  .b-link {
    a {
      text-decoration: underline;
    }
  }
`;

const UpperFooter = styled('div')`
  background: ${theme.colors.accent};
  color: #333;
  ${theme.below.lg} {
    > div {
      flex-direction: column-reverse;
      .right {
        text-align: left;
        max-width: 100%;
        margin-bottom: 30px;
        h2 {
          font-size: 2rem;
        }
      }
    }
  }
`;

const NewsletterWrapper = styled('div')``;

const PaymentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  ${theme.below.lg} {
    align-self: flex-start;
    padding-bottom: 1rem;
  }

  svg {
    color: white;
  }
`;

const Footer = () => {
  const { isB2B } = useChannelCheck();
  const { data } = useQuery(NewsletterQuery, {
    variables: {
      id: 231
    }
  });
  const kampanj = data?.category?.subcategories[2];
  const text = data?.category?.subcategories[1];
  const title = data?.category?.subcategories[0];

  return (
    <Wrapper>
      <UpperFooter>
        <Inner>
          <Column className="left">
            <FooterLinks />
          </Column>
          <Column className="right">
            <h2>
              Exlusiva motiv <br /> att älska över tid.
            </h2>
          </Column>
        </Inner>
      </UpperFooter>
      <LowerFooter>
        <Inner className="bottom">
          <Column className="right payment">
            {!isB2B && (
              <NewsletterWrapper>
                <NewsletterField
                  footer={true}
                  title={title}
                  text={text}
                  kampanj={kampanj}
                />
              </NewsletterWrapper>
            )}
            <PaymentWrapper>
              <Klarna />
              <DHL className={'dhl-svg'} />
            </PaymentWrapper>
          </Column>
          <Column className="left">
            <div className="group">
              <a href="mailto:hello@designedposters.com">
                hello@designedposters.com
              </a>
            </div>

            <div className="group address">
              <a
                href="https://g.page/r3reklambyra"
                target="_blank"
                rel="noreferrer"
              >
                <p>Designed Posters</p>
                <p>c/o R3 Sweden AB</p>
                <p>Nils Jakobsonsgatan 5 H</p>
                <p>504 30 Borås</p>
              </a>
            </div>

            {isB2B ? (
              <div className="b-link">
                <a href="/se/?culture=sv-SE&currency=SEK&channelCountry=SE&channel=SE">
                  Inte företagskund? Klicka här.
                </a>
              </div>
            ) : (
              <div className="b-link">
                <a href="/b2b/?culture=sv-SE&currency=SEK&channelCountry=SE&channel=b2b">
                  B2B-kund? Klicka här.
                </a>
              </div>
            )}

            <div className="group">
              <SocialLinks showLabel={false} />
            </div>

            <PoweredByWrapper></PoweredByWrapper>
          </Column>
        </Inner>
      </LowerFooter>
    </Wrapper>
  );
};

export default Footer;
